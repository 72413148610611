import React, {useState, useContext, useEffect, useRef} from 'react'
import { Functionality } from '../context/Functionality'
import { Text } from '../context/Text'
import { GoPaperAirplane } from "react-icons/go";


export default function Contact(props){

    const {inputs, busInputs, setInputs, setBusInputs, handleChange, handleBusChange, addStudent, addBusiness, handleSubmit, handleBusSubmit, sendClicked, setSendClicked, showForm, setShowForm, contactClicked} = useContext(Functionality)
    const {contactUs} = useContext(Text)
    const {} = props

    const [showBusiness, setShowBusiness] = useState(false);
    

    return(
        <div className="home-contact">
            { contactClicked && 
            <>
            <div className="home-whatWeOffer" style={{paddingBottom: "10vw"}}>
                <h1 className="home-subject-head" style={{textAlign: "center", width: "100%"}}>Are you a</h1>
                <button className="home-subject-button" onClick={() => setShowForm(true)} style={{width: "250px"}}><span className="text">BUSINESS</span></button><span className="home-subject-head" style={{fontWeight: "bold"}}>   or   </span>
                <button className="home-subject-button" onClick={() => setShowForm(false)} role="button" style={{width: "250px"}}><span className="text">POTENTIAL STUDENT</span></button>
            </div>
            </>
            }
            {showForm && 
            <>
            <h1 style={{color: "white"}}>Fill out the form and we will contact you within a few hours.</h1>
            <form className="home-contact-form" onSubmit={handleBusSubmit}>
                <input
                    id="name"                     
                    name="name"
                    value={busInputs.name}
                    placeholder="Name of Business*"
                    type="text"
                    required
                    onChange={handleBusChange}
                />
                <input
                    id="email"                     
                    name="email"
                    value={busInputs.email}
                    placeholder="Email*"
                    type="email"
                    required
                    onChange={handleBusChange}
                />
                <input
                    id="phoneNumber"                   
                    name="phoneNumber"
                    value={busInputs.phoneNumber}
                    placeholder="Phone Number*"
                    type="phone"
                    required
                    onChange={handleBusChange}
                />
                <input
                    id="date"                     
                    name="date"
                    value={busInputs.date}
                    type="date"
                    required
                    onChange={handleBusChange}
                />
                <input
                    id="referralCode"                     
                    name="referralCode"
                    placeholder='Referral Code'
                    value={busInputs.referralCode}
                    type="text"
                    onChange={handleBusChange}
                />
                <textarea id="message" name="message" placeholder="What would you like us to know?" type="textarea" value={busInputs.message} onChange={handleBusChange}></textarea>
                 <button className={sendClicked ? "home-contact-form-button-clicked" : "home-contact-form-button"} id="contact-button" onClick={() => setSendClicked(true)}>
                    <GoPaperAirplane style = {{transform: 'rotate(-45deg)' }} className="send2" aria-hidden="true" />
                    <p>Send</p>
                </button>
            </form>
            </>
            }
            {!showForm &&
            <>
            <h1 style={{color: "white"}}>Please use this form to contact us</h1>
            <form className="home-contact-form" onSubmit={handleSubmit}>
                <input
                    id="firstName"
                    name="firstName"
                    value={inputs.firstName}
                    placeholder="First Name*"
                    type="text"
                    required
                    onChange={handleChange}
                />
                <input
                    id="lastName"
                    name="lastName"
                    value={inputs.lastName}
                    placeholder="Last Name*"
                    type="text"
                    required
                    onChange={handleChange}
                />
                <input
                    id="email"
                    name="email"
                    value={inputs.email}
                    placeholder="Email*"
                    type="email"
                    required
                    onChange={handleChange}
                />
                <input
                    id="phoneNumber"
                    name="phoneNumber"
                    value={inputs.phoneNumber}
                    placeholder="Phone Number"
                    type="phone"
                    onChange={handleChange}
                />
                <input 
                    id="date"
                    name="date"
                    value={inputs.date}
                    type="date"
                    required
                    onChange={handleChange}
                />
                <input
                    id="referralCode"                     
                    name="referralCode"
                    placeholder='Referral Code'
                    value={inputs.referralCode}
                    type="text"
                    onChange={handleChange}
                />
                <textarea id="message" name="message" placeholder="Anything else you'd like us to know?" type="textarea" value={inputs.message} onChange={handleChange}></textarea>
                <button className={sendClicked ? "home-contact-form-button-clicked" : "home-contact-form-button"} id="contact-button" onClick={() => setSendClicked(true)}>
                    <GoPaperAirplane style = {{transform: 'rotate(-45deg)' }} className="send2" aria-hidden="true" />
                    <p>Send</p>
                </button>
            </form>
            </>
            }
        </div>
    )
}