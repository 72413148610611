import { useState, useEffect, useContext, useRef } from 'react'
import { BrowserRouter as Routes, Route, Link } from 'react-router-dom'
import ReactPlayer from 'react-player'
import Design from '../outside/design.png'
import { Text } from '../context/Text.js'
import { Functionality } from '../context/Functionality.js'
import { FaCircle } from 'react-icons/fa'
import EmployeePortal from './EmployeePortal.js'
import Contact from './Contact.js'
import BottomNav from './BottomNav.js'

export default function Home() {

    const { aboutUs, whatWeOffer, testimonials } = useContext(Text)
    const { slideNum, setSlideNum, contactClicked, setContactClicked, contact, token, showForm, setShowForm } = useContext(Functionality)
    function turnOffContactClicked() {
        setContactClicked(false)
        setShowForm(true)
    }

    function handleClick() {
        window.scrollTo(0, 0); // Scroll to the top of the page
        setShowForm(false); // Assuming setShowForm is a state updater function
    }

    return (
        <>
            { !token && <div className="home-wrapper">
                <div className="home-aboutUs">
                    <h1 className="home-subject-head" id="about-us">{aboutUs}</h1>
                </div>
                <div className="home-whatWeOffer" style={{ paddingBottom: "10vw" }}>
                    <h1 className="ios-description">Are you a</h1>
                    <Link to="/clients">
                        <button className="home-subject-button" onClick={handleClick} role="button" style={{ width: "250px" }}>
                            <span className="text">BUSINESS</span>
                        </button>

                    </Link>
                    <span className="ios-description" style={{ fontWeight: "bold" }}>   or   </span>
                    <Link to="/ios">
                        <button className="home-subject-button" onClick={handleClick} role="button" style={{ width: "250px" }}>
                            <span className="text">POTENTIAL STUDENT</span>
                        </button>
                    </Link>
                </div>
                <h1 className="ios-description" style={{ paddingTop: "10%" }}>Hear from Blake</h1>
                <ReactPlayer
                    className="home-video"
                    url={"https://vimeo.com/979950260"}
                    controls
                    style={{width: "90vw !important"}}
                />
            </div>}
        </>
    )
}