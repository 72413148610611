import React, { useState, useEffect, useContext } from 'react';
import { Functionality } from '../context/Functionality';

export default function EmployeeStudentCard(props) {
  const { student } = props;
  const { accepted, declined, enrolled, processing, uncontacted, note, data, _id } = student;
  const { firstName, lastName, course, email, phoneNumber, date, message, referralCode } = data;
  const { handleDelete, editStudent } = useContext(Functionality);

  const initRadio = {
    uncontacted: false,
    processing: false,
    accepted: false,
    enrolled: false,
    declined: false,
  };

  const [radio, setRadio] = useState(initRadio);
  const [localInputs, setLocalInputs] = useState({
    note: note || ""
  });

  function handleChangeRadio(e) {
    const { name } = e.target;
    setRadio(prev => ({
      ...initRadio,
      [name]: true
    }));
  }

  function handleInputChange(e) {
    const { name, value } = e.target;
    setLocalInputs(prevInputs => ({
      ...prevInputs,
      [name]: value
    }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    const updatedInputs = { ...localInputs, note: localInputs.note };
    editStudent(_id, updatedInputs);
  }

  return (
    <div className={uncontacted ? "students-wrapper-uncontacted" : processing ? "students-wrapper-processing" : accepted ? "students-wrapper-accepted" : enrolled ? "students-wrapper-enrolled" : declined && "students-wrapper-declined"}>
      <h3 className="students-wrapper-namesCourse">{firstName} {lastName}</h3>
      <h4 className="students-wrapper-email">Email: {email}</h4>
      <h4 className="students-wrapper-phone">Phone #: {phoneNumber}</h4>
      <h4 className="students-wrapper-message">Message: {message}</h4>
      <h4 className="students-wrapper-date">Date: {date}</h4>
      <h4 className="students-wrapper-referralCode">Code: {referralCode}</h4>
      <h4 className="students-wrapper-note">Employee Note: {note}</h4>
      <form className="students-notesForm" onSubmit={handleSubmit}>
        <textarea
          className="business-notesForm-textarea"
          id="note"
          name="note"
          value={localInputs.note}
          onChange={handleInputChange}
        ></textarea>
        <button type="submit">Add Note</button>
      </form>
      <form className="students-form">
        <div>
          <label>Un
            <input
              type="radio"
              name="uncontacted"
              value={true}
              checked={radio.uncontacted}
              onChange={handleChangeRadio}
            />
          </label>
        </div>
        <div>
          <label>Pro
            <input
              type="radio"
              name="processing"
              value={true}
              checked={radio.processing}
              onChange={handleChangeRadio}
            />
          </label>
        </div>
        <div>
          <label>Acc
            <input
              type="radio"
              name="accepted"
              value={true}
              checked={radio.accepted}
              onChange={handleChangeRadio}
            />
          </label>
        </div>
        <div>
          <label>En
            <input
              type="radio"
              name="enrolled"
              value={true}
              checked={radio.enrolled}
              onChange={handleChangeRadio}
            />
          </label>
        </div>
        <div>
          <label>De
            <input
              type="radio"
              name="declined"
              value={true}
              checked={radio.declined}
              onChange={handleChangeRadio}
            />
          </label>
        </div>
      </form>
      <button className="students-delete" onClick={() => editStudent(_id, radio)}>Transfer Student</button>
      <button className="students-delete" onClick={() => handleDelete(_id)}>Remove Student</button>
    </div>
  );
}
