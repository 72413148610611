import React, {useState, useContext, useEffect, useRef} from 'react'
import Ios from './courses/Ios.js'
import Business from './clients/Business.js'
import Logo from '../outside/Logo.png'
import {Functionality} from '../context/Functionality.js'
import {BrowserRouter as Routes, Route, Link} from 'react-router-dom'
import { FaRegArrowAltCircleUp } from "react-icons/fa";
import AuthForm from './AuthForm.js'
import Hamburger from './Hamburger.js'



export default function Navbar(){

const {homeClick, coursesClick, scrollToTop, showButton, contactClicked, setContactClicked, setShowForm, hamClicked, setHamClicked} = useContext(Functionality)

    const [size, setSize] = useState(window.innerWidth)
    function resize() {
        setSize(window.innerWidth)
    }
    window.onresize = resize;

    function contact(){
        setShowForm(false)
        setContactClicked(true)
        setHamClicked(false)
    }

    return(
        <div className="nav-wrapper">
            <AuthForm />
            <div className="nav-container">
                <img 
                    className="nav-container-img"
                    src={Logo}
                    alt="Img loading/No Image Available"
                    onClick={homeClick}
                />
                <h1 className="nav-container-title" onClick={homeClick}>BLAKE<br></br>TECHNOLOGIES</h1>
                { size < 700 && <Hamburger />}
                { hamClicked && 
                <div className="hamburger-menu-container">
                    <h1 className="hamburger-menu-x" onClick={() => setHamClicked(false)}>X</h1>
                    <Link to="/" className="hamburger-menu-home" onClick={() => setHamClicked(false)}>Home</Link>
                    <Link to="/clients" className="hamburger-menu-business" onClick={() => setHamClicked(false)}>Business</Link>
                    <Link to="/ios" className="hamburger-menu-ios" onClick={() => setHamClicked(false)}>iOS Course</Link>
                    <Link to='/loan' className="hamburger-menu-financing" onClick={() => setHamClicked(false)}>Financing</Link>
                    <Link to="/contact" className="hamburger-menu-contact" onClick={() => contact()}>Contact</Link>
                </div>
                }
                <div className="nav-container-menus">
                    <Link to="/" className="nav-container-menus-home">Home</Link>
                    <Link to="/clients" className="nav-container-menus-home">Business</Link>
                    <Link to="/ios" className="nav-container-menus-home">iOS Course</Link>
                    <Link to='/loan' className="nav-container-menus-home">Financing</Link>
                    <Link to="/contact" className="nav-container-menus-home" onClick={() => contact()}>Contact</Link>
                </div>
            </div>
           {showButton && <FaRegArrowAltCircleUp className="scroll-button"onClick={scrollToTop}/>}
        </div>
    )
}