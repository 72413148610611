import React, { useState, useEffect, useContext } from 'react'
import FullTimeCourse from '../../outside/CourseFullTime.pdf'
import SelfPaceCourse from '../../outside/iOSSelfPaceSyllabus.pdf'
import Contact from '../Contact.js'
import ReactPlayer from 'react-player'
import Design from '../../outside/design.png'
import { Text } from '../../context/Text.js'
import { Functionality } from '../../context/Functionality.js'
import { PiArrowBendDownRightBold } from "react-icons/pi";
import { FaFilePdf } from "react-icons/fa6";
import { BrowserRouter as Routes, Route, Link } from 'react-router-dom'


export default function Ios() {

    const { description, mappedArr, iosSubTitle, daily } = useContext(Text)
    const { viewObj, setViewObj, showForm, setShowForm, setContactClicked } = useContext(Functionality)

    const [toggle, setToggle] = useState(false)
    const clicked = () => {
        setToggle(true)
        setShowForm(false)
        setContactClicked(false)
    }

    return (
        <div className="ios-wrapper">
            <div className="ios-top-content">
                <p className="ios-description">{description}</p>
                <button className="home-subject-button" onClick={clicked} style={{ marginTop: "20px", marginBottom: "20px" }}><span>Contact for Info</span></button>
                {toggle && <Contact />}
                <div className="container">
                    <div className="left">
                        <h1 className="ios-description" style={{ paddingTop: "10%" }}>iOS Full Time</h1>
                        <h2 className="ios-price">$19,950</h2>
                        <h2 className="left-date" style={{ color: 'white' }}>15 spots left! Starts on Nov 4</h2>
                        <h1 className="ios-description" style={{ paddingTop: "5%" }}>What's included?</h1>
                        <div className="ios-list">
                            <h2 style={{ color: 'white' }}>Daily instruction by Senior Engineers.</h2>
                            <h2 style={{ color: 'white' }}>Build Apps for all Apple devices including Vision Pro.</h2>
                            <h2 style={{ color: 'white' }}>Submit your own App onto the AppStore.</h2>
                            <h2 style={{ color: 'white' }}>Work on real client Projects to build your resume.</h2>
                            <h2 style={{ color: 'white' }}>Learn how to pass job interviews.</h2>
                        </div>
                        <div>
                            <a className="home-subject-button" href={FullTimeCourse} style={{ display: "inline-block", marginRight: "auto" }}><span>VIEW SYLLABUS</span></a>
                        </div>
                    </div>
                    <div className="right">
                        <h1 className="ios-description" style={{ paddingTop: "10%" }}>iOS Self Pace</h1>
                        <h2 className="ios-price">$199 / mo</h2>
                        <h2 className="right-date" style={{ color: 'white' }}>No commitment + At your own pace</h2>
                        <h1 className="ios-description" style={{ paddingTop: "5%" }}>What's included?</h1>
                        <div className="ios-list">
                            <h2 style={{ color: 'white' }}>Build Apps compatible for all Apple devices.</h2>
                            <h2 style={{ color: 'white' }}>App Store Submission Instructions.</h2>
                            <h2 style={{ color: 'white' }}>Slack Community with other students and instructors.</h2>
                            <h2 style={{ color: 'white' }}>Hundreds of hours of updated material.</h2>
                        </div>
                        <div>
                            <a className="home-subject-button" href={SelfPaceCourse} style={{ display: "inline-block", marginRight: "auto" }}><span>VIEW SYLLABUS</span></a>
                            <a className="home-subject-button" href="https://buy.stripe.com/cN22bLf219VydSEbII"  style={{ display: "inline-block", marginRight: "auto" }}><span>Start Subscription</span></a>
                        </div>
                    </div>
                </div>
                <h1 className="ios-description" style={{ paddingTop: "5%" }}>Additional Services</h1>
                <div className="ios-list">
                    <h2 style={{ color: 'white' }}>1. Resume Prep: 1x fee of $200</h2>
                    <h2 style={{ color: 'white' }}>2. Salary Negotiation: We get half of the increase from the original offer</h2>
                    <h2 style={{ color: 'white' }}>3. Mock Interviews with a Senior Engineer: $100 per interview</h2>
                    <h2 style={{ color: 'white' }}>4. Client Project to build resume: Ask about details</h2>
                    <h2 style={{ color: 'white' }}>5. Personal Project Assistence with Senior Engineer: $100 / hr</h2>
                </div>
                <h1 className="ios-description" style={{ paddingTop: "5%" }}>What skills will I learn?</h1>
                <div className="ios-list">
                    <h2 style={{ color: 'white' }}>1. MVVM and SwiftUI which is Apples preferred UI design framework for all devices.</h2>
                    <h2 style={{ color: 'white' }}>2. Persistence with FileManager, SwiftData, CoreData, Closures.</h2>
                    <h2 style={{ color: 'white' }}>3. Networking with APIs using URLSession DataTask, JSONSerialization, Codable Protocol, Concurrency.</h2>
                    <h2 style={{ color: 'white' }}>4. Advanced Networking with image data, Async Await, Combine.</h2>
                    <h2 style={{ color: 'white' }}>5. Build apps with Firebase Databases with fetching and storing images.</h2>
                    <h2 style={{ color: 'white' }}>And Much More!!</h2>
                </div>
                <h1 className="ios-description" style={{ paddingTop: "5%" }}>Blakes thoughts about the course</h1>
                <ReactPlayer
                    className="home-video"
                    url={"https://vimeo.com/954249871"}
                    controls
                />
            </div>
        </div>
    )
}