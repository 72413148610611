import React, { useState, useEffect, useContext } from 'react'
import Contact from '../Contact.js'
import Design from '../../outside/design.png'
import ReactPlayer from 'react-player'
import { Text } from '../../context/Text.js'
import { Functionality } from '../../context/Functionality.js'
import { PiArrowBendDownRightBold } from "react-icons/pi";
import { FaFilePdf } from "react-icons/fa6";
import { BrowserRouter as Routes, Route, Link } from 'react-router-dom'


export default function Business() {

    const { businessDescription, mappedArr, iosSubTitle, daily } = useContext(Text)
    const { viewObj, setViewObj, showForm, setShowForm, setContactClicked } = useContext(Functionality)

    const [toggle, setToggle] = useState(false)
    const clicked = () => {
        setToggle(true)
        setShowForm(false)
        setContactClicked(false)
    }

    return (
        <div className="ios-wrapper">
            <div className="ios-top-content">
                <p className="ios-description">{businessDescription}</p>
                <img
                    className="ios-img"
                    src={Design}
                    style={{ zIndex: "-1" }}
                />
                <h1 className="ios-description" style={{ paddingTop: "3%" }}>How does it work?</h1>
                <ul className="ios-list">
                    <li style={{ color: 'white' }}>Submit a contact form and we will contact you to schedule a consultaion.</li>
                    <li style={{ color: 'white' }}>We will decide on a feature set for an App.</li>
                    <li style={{ color: 'white' }}>Our students will build the App as part of the curriculum.</li>
                </ul>
                <h1 className="ios-description" style={{ paddingTop: "10%" }}>Hear from Blake</h1>
                <ReactPlayer
                    className="home-video"
                    url={"https://vimeo.com/954245543"}
                    controls
                />
            </div>
        </div>
    )
}